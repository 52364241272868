// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function MuiCard() {
  return {
    MuiCardActions: {
      styleOverrides: {
        root: {
          // padding: '0px'
        }
      }
    }
  };
}
